/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { UserProvider } from "./src/context/UserContext"

// This makes the use provider available to the whole app
export const wrapRootElement = ({ element }) => {
    return <UserProvider> { element } </UserProvider>
}


// window.ethereum.on("accountsChanged", ([newAddress]) => {
//     window.location.reload();
// });
// // When user changes their network.
// window.ethereum.on("chainChanged", ([newChainId]) => {  
//     window.location.reload();
// });