import React, {useState, createContext } from "react"

const defaultState = {
    wallet: { isConnect: false, address: null },
    setWallet: () => {}
}

const UserContext = createContext(defaultState);

function UserProvider( { children } ) {
    const [wallet, setWallet] = useState({ isConnect: false, address: null })

    return (
        <UserContext.Provider value ={{ wallet, setWallet }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider }